<template>
  <div class="view-file-box">
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="文件查看"
      width="700px"
      destroy-on-close
    >
      <div v-if="showDel" style="text-align: right;">
        <el-button type="primary" @click="patchDel" size="small"><el-icon><Delete /></el-icon>&nbsp;删除选中文件</el-button>
      </div>
      <div class="file-box">
        <div v-for="item in fileList" :key="item.url" class="file-item" @click="() => showFile(item)">
          <i v-if="item.icon" :class="['iconfont', item.icon]"></i>
          <img v-else :src="item.url" class="file-img" alt="">
          <p class="row-ellipsis">{{ item.fileName }}</p>
          <el-checkbox v-if="showDel" size="small" v-model="item.check" @click.stop class="view-file-checkbox"></el-checkbox>
        </div>
      </div>
      <template #footer>
        <span v-if="showDel" class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="saveFile">确认</el-button>
        </span>
        <span v-else><el-button size="small" @click="dialogFormVisible = false">关闭</el-button></span>
      </template>
    </el-dialog>

    <el-image-viewer
      v-if="showImagePreview"
      :zoom-rate="1.2"
      @close="showImagePreview = false"
      :url-list="imgSrc"
      :initial-index="currentIndex"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import { Delete } from '@element-plus/icons-vue'

import { uploadPaymentRecordFile } from '@/api/newCrm'

const props = defineProps({
  showDel: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['success'])

const dialogFormVisible = ref(false)
const fileList = ref([])
const innerRow = ref()
const showImagePreview = ref(false)
const imgSrc = ref('')
const currentIndex = ref(0)

const extIcon = {
  txt: 'icon-wenjianleixing-biaozhuntu-jishiben',
  doc: 'icon-wenjianleixing-biaozhuntu-Wordwendang',
  docx: 'icon-wenjianleixing-biaozhuntu-Wordwendang',
  xls: 'icon-wenjianleixing-biaozhuntu-gongzuobiao',
  xlsx: 'icon-wenjianleixing-biaozhuntu-gongzuobiao',
  pdf: 'icon-wenjianleixing-biaozhuntu-PDFwendang',
}

function saveFile() {
  if (props.showDel) {
    uploadPaymentRecordFile({
      file: fileList.value.map(item => item.url),
      paymentId: innerRow.value.id
    }).then(res => {
      if (res.data.msg == "success") {
        ElMessage.success('修改成功')
        emit('success')
        dialogFormVisible.value = false
      }
    })
  } else {
    dialogFormVisible.value = false
  }
}

function getLast(arr) {
  return arr[arr.length - 1]
}

function show(row) {
  dialogFormVisible.value = true
  innerRow.value = row
  fileList.value = row.file ? row.file.split(',').filter(item => !!item).map(u => {
    const ext = getLast(u.split('.'))
    const name = getLast(u.split('/'))
    return {
      url: u,
      type: ext,
      fileName: name.replace(/\d+/g, ''),
      icon: extIcon[ext],
      check: false,
    }
  }) : []
}

function showFile(item) {
  if (!['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(item.type)) {
    window.open(item.url)
  } else {
    imgSrc.value = [item.url]
    showImagePreview.value = true
  }
}

function patchDel() {
  fileList.value = fileList.value.filter(item => !item.check)
}

defineExpose({
  show,
})
</script>

<style lang="less" scoped>
.file-box {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .file-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 120px;

    .file-img {
      display: block;
      width: 100px;
      height: 120px;
      object-fit: cover;
    }

    .iconfont {
      font-size: 60px;
    }

    p {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 20px;
      padding: 0 5px;
      text-align: center;
      background-color: #c8c9cc;
    }

    .el-checkbox {
      position: absolute;
      top: 5px;
      left: 5px;
      height: auto;
    }
  }
}

</style>
<style lang="less">
.view-file-checkbox {
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: var(--themeColor);
  }
}
</style>
